import React from 'react';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import TopNav from '../components/TopNav';
import MobileView from '../components/MobileView';

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

const ContentWrapper = styled(Box)({
  flexGrow: 1,
  paddingTop: 64,
});

const MainLayout = ({ children }) => {
  const showContent = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  if (!showContent)
    return (
      <Wrapper alignItems="center" justifyContent="center">
        <MobileView />
      </Wrapper>
    );

  return (
    <Wrapper>
      <TopNav />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

MainLayout.propTypes = {
  children: PropTypes.object,
};

export default MainLayout;
