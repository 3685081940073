const palette = {
  background: {
    default: '#fafafa',
  },
  primary: {
    100: '#EFF3FA',
    200: '#DCE8F9',
    300: '#BAD1E8',
    400: '#3098E8',
    500: '#3380CC',
    600: '#284886',
    700: '#213863',
    800: '#0A255C',
    900: '#101841',
  },
  neutral: {
    100: '#FFFFFF',
    200: '#F9FAFB',
    300: '#EEF0F1',
    400: '#D8DBDF',
    500: '#C1C7CD',
    600: '#A5ADB6',
    700: '#848F9A',
    800: '#5C6670',
    900: '#2E3338',
  },
  success: {
    100: '#E7FEE7',
    200: '#BEFBB1',
    300: '#9EF075',
    400: '#6EE830',
    500: '#44CC00',
    600: '#28BD28',
    700: '#2AA112',
    800: '#367D41',
    900: '#335C0A',
  },
  warning: {
    100: '#FEFAE6',
    200: '#FEF2C3',
    300: '#F7EC6E',
    400: '#F5E63D',
    500: '#F9D006',
    600: '#FFAA00',
    700: '#ED8F31',
    800: '#CF5417',
    900: '#A14112',
  },
  error: {
    100: '#FEEEEB',
    200: '#FCD6CF',
    300: '#FAAD9E',
    400: '#F7856E',
    500: '#FF2B00',
    600: '#C20D0A',
    700: '#A12A12',
    800: '#7A261F',
    900: '#661100',
  },
  text: {
    primary: '#2E2C34',
    secondary: '#84818A',
  },
};

export default palette;
