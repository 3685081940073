import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { datadogRum } from '@datadog/browser-rum';
import { auth } from '../components/UserProvider';

import { createLogger } from '../utils/logger';

import constants from '../constants';
const log = createLogger('services::client');

const fetcher = (...args) => window.fetch(...args);

const httpLink = createHttpLink({
  uri: process.env.API_URL,
  fetch: fetcher,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(constants.STORAGE_TOKEN_KEY);

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default new ApolloClient({
  uri: process.env.API_URL,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  // typeDefs,
  // resolvers,
  onError: async (error) => {
    log.error({ error });
    const { graphQLErrors, operation, forward } = error;
    let refreshSession = false;
    graphQLErrors.forEach((graphQLError) => {
      datadogRum.addError(graphQLError, undefined, 'network');
      if (graphQLError.extensions.code === 'UNAUTHENTICATED') {
        refreshSession = true;
      } else if (
        graphQLError.extensions.code === 'INTERNAL_SERVER_ERROR' &&
        graphQLError.message === 'Context creation failed: read ECONNRESET'
      ) {
        // retrying request to mitigate the connection reset issue.
        return forward(operation);
      }
      return true;
    });
    if (refreshSession) {
      try {
        await auth.renewSession();
        return forward(operation);
      } catch (err) {
        log.error({ err });
      }
    }
    return true;
  },
});
