/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import loadable from '../utils/loadable';
import { Routes } from '../utils/constants/routes';

import GlobalStyle from '../global-styles';

// page imports
import DefaultLayout from '../layout/index';
import useUser from '../components/useUser';
import { createLogger } from '../utils/logger';

const log = createLogger('routes:::');

const Dashboard = loadable(() => import('./home'));
const Canvas = loadable(() => import('./dashboard'));
const Inventory = loadable(() => import('./inventory'));
const ApplicationItem = loadable(() => import('./inventory/application-item'));
const ComponentItem = loadable(() => import('./inventory/components-item'));
const PortfoliosItem = loadable(() => import('./inventory/portfolios-item'));
const BusinessesItem = loadable(() => import('./inventory/businesses-item'));
const CapabilitiesItem = loadable(() => import('./inventory/capabilities-item'));
const Settings = loadable(() => import('./settings'));
const IntegrationItem = loadable(() => import('./settings/integration-item'));
const Profile = loadable(() => import('./profile'));
const Landing = loadable(() => import('./landing'));
const ForgotPassword = loadable(() => import('./forgot-password'));
const People = loadable(() => import('./people'));
const SinglePersonView = loadable(() => import('./people/single-person-view'));
const SingleTeamView = loadable(() => import('./people/single-team-view'));
const PeopleSingleRole = loadable(() => import('./people/single-role-view'));
const PeopleTeams = loadable(() => import('./people/teams'));
const PeopleRoles = loadable(() => import('./people/roles'));
// const ReportingExplore = loadable(() => import('./reporting/pages/ExplorePage'));
// const ReportingDashboard = loadable(() => import('./reporting/pages/DashboardPage'));
const Explore = loadable(() => import('./explore'));
const ExploreCapabilities = loadable(() => import('./explore/capabilities'));
const DataFlow = loadable(() => import('./dataFlow'));

const PrivateRoutes = (props) => {
  const { user, children } = props;

  if (!user.isLoggedIn) return <Redirect to="/landing" />;

  return children;
};

const Logout = () => {
  const userComponent = useUser();
  log.info('logout route fired.');
  userComponent.handlers.onLogout();

  return <div>...</div>;
};

function Router() {
  const { user } = useUser();
  return (
    navigator.onLine && (
      <>
        <Switch>
          <Route exact path={Routes.LANDING} render={() => <Landing />} />
          <Route exact path={Routes.FORGOT_PASSWORD} render={() => <ForgotPassword />} />
          <Route exact path={Routes.LOGOUT} render={() => <Logout />} />
        </Switch>

        <PrivateRoutes user={user}>
          <DefaultLayout>
            <Switch>
              <Route exact path={Routes.ROOT} component={Dashboard} />
              <Route exact path={Routes.EXPLORE} component={Explore} />
              <Route exact path={Routes.EXPLORE_CAPABILITIES} component={ExploreCapabilities} />
              <Route exact path={Routes.CANVAS} component={Canvas} />
              <Route exact path={Routes.DATA_FLOW} component={DataFlow} />
              <Route exact path={Routes.SINGLE_BUSINESS} component={BusinessesItem} />
              <Route exact path={Routes.SINGLE_APPLICATION} component={ApplicationItem} />
              <Route exact path={Routes.SINGLE_PORTFOLIO} component={PortfoliosItem} />
              <Route exact path={Routes.SINGLE_COMPONENT} component={ComponentItem} />
              <Route exact path={Routes.SINGLE_CAPABILITY} component={CapabilitiesItem} />
              <Route path={Routes.INVENTORY} component={Inventory} />
              <Route exact path={Routes.SETTINGS_SINGLE_INTEGRATION} component={IntegrationItem} />
              <Route path={Routes.SETTINGS} component={Settings} />
              <Route exact path={Routes.PROFILE} component={Profile} />
              <Route exact path={Routes.TEAMS} component={PeopleTeams} />
              <Redirect from={Routes.SINGLE_TEAM} to={`${Routes.SINGLE_TEAM}/summary`} exact />
              <Route path={Routes.SINGLE_TEAM} component={SingleTeamView} />
              <Route exact path={Routes.ROLES} component={PeopleRoles} />
              <Route exact path={Routes.SINGLE_ROLE} component={PeopleSingleRole} />
              <Route exact path={Routes.PEOPLE} component={People} />
              <Route exact path={Routes.SINGLE_PERSON} component={SinglePersonView} />
              {/* <Route exact path={Routes.REPORTING_EXPLORE} component={ReportingExplore} />
            <Route exact path={Routes.REPORTING_DASHBOARD} component={ReportingDashboard} /> */}
              <Route render={() => <Redirect to={Routes.ROOT} />} />
            </Switch>
          </DefaultLayout>
        </PrivateRoutes>
        <GlobalStyle />
      </>
    )
  );
}

export default withRouter(Router);
