export const EntityTypes = {
  PORTFOLIO: 'portfolio',
  APPLICATION: 'application',
  COMPONENT: 'component',
  CAPABILITY: 'capability',
  BUSINESS: 'business',
  ASSIGNMENT: 'assignment',
  LOCATION: 'location',
  TEAM: 'team',
  PERSON: 'person',
};
