import { gql } from '@apollo/client';

export const GET_BOOTSTRAP = gql`
  query {
    currentAccount {
      id
      name
      shortId
    }
    currentUser {
      id
      firstName
      lastName
      email
      phone
      status
      personId
    }
  }
`;
