import MenuBookIcon from '@mui/icons-material/MenuBook';
import MonitorIcon from '@mui/icons-material/Monitor';
import DnsIcon from '@mui/icons-material/Dns';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ScienceIcon from '@mui/icons-material/Science';

import ViewListIcon from '@mui/icons-material/ViewList';
import GroupsIcon from '@mui/icons-material/Groups';
import BadgeIcon from '@mui/icons-material/Badge';

import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventIcon from '@mui/icons-material/Event';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BuildIcon from '@mui/icons-material/Build';
import WarningIcon from '@mui/icons-material/Warning';
import SchemaIcon from '@mui/icons-material/Schema';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const Icons = {
  SINGLE_PORTFOLIO: MenuBookIcon,
  SINGLE_APPLICATION: MonitorIcon,
  SINGLE_COMPONENT: DnsIcon,
  SINGLE_CAPABILITY: FormatListBulletedIcon,
  SINGLE_BUSINESS: BusinessCenterIcon,
  SINGLE_TECHNOLOGY: ScienceIcon,
  ROSTER: ViewListIcon,
  TEAM: GroupsIcon,
  ROLE: BadgeIcon,
  SERVICE: SchemaIcon,
  SEARCH: SearchIcon,
  EDIT: EditIcon,
  EDIT_OUTLINED: EditOutlinedIcon,
  CALENDAR: EventIcon,
  GROUP: GroupIcon,
  PERSON: PersonIcon,
  NO_PERSON: PersonOffIcon,
  ACCOUNT: AccountCircleIcon,
  ROCKET_LAUNCH: RocketLaunchIcon,
  BUILD: BuildIcon,
  WARNING: WarningIcon,
  CLOSE_ICON: CloseIcon,
  COPY: ContentCopyIcon,
  ADD: AddIcon,
  MORE: MoreVertIcon,
  ERROR: HighlightOffIcon,
  CLOCK: WatchLaterOutlinedIcon,
};
