import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grey from '@material-ui/core/colors/grey';

import { SEARCH_ENTITIES } from '../../services/search';

import { createLogger } from '../../utils/logger';
import { Icons } from '../../utils/constants/icons';
import { EntityTypes } from '../../utils/constants/entityTypes';
const log = createLogger('SearchModal::form');

const useStyles = makeStyles((theme) => ({
  control: {
    margin: theme.spacing(0),
    width: '100%',
    display: 'block',
  },
  field: {
    margin: 0,
    width: '100%',
    display: 'block',
  },
  inputCustom: {
    color: Grey[300],
    paddingLeft: 5,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: '.85rem',
  },
  icon: {
    color: Grey[300],
  },
}));

const excludeEntityTypes = new Set([EntityTypes.ASSIGNMENT]);

const SearchForm = ({ callback, loadingCallBack }) => {
  const classes = useStyles();

  const [searchAPICall] = useLazyQuery(SEARCH_ENTITIES, {
    onCompleted: (data) => {
      log.info('searchAPICall Response:', data);
      const filteredResult = data.search.entities.filter((entity) => !excludeEntityTypes.has(entity.type));
      callback(filteredResult);
      loadingCallBack(false);
    },
    onError: (error) => {
      log.error('search error', { error });
      loadingCallBack(false);
    },
    fetchPolicy: 'cache-first',
  });

  const handleChange = (event) => {
    const searchStr = event.target.value;
    if (searchStr.length > 2) {
      loadingCallBack(true);
      searchAPICall({
        variables: {
          query: searchStr.trim(),
          limit: 100,
        },
      });
    } else {
      loadingCallBack(false);
    }
  };

  return (
    <FormControl fullWidth className={classes.control}>
      <TextField
        className={classes.field}
        // disableUnderline
        id="input-with-icon-textfield"
        // label="TextField"
        placeholder="Search..."
        onChange={handleChange}
        autoFocus
        fullWidth
        InputProps={{
          className: classes.inputCustom,
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" className={classes.icon}>
              <Icons.SEARCH />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default SearchForm;
