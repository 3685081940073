import _ from 'lodash';
import auth0 from 'auth0-js';
import decode from 'jwt-decode';
import { datadogRum } from '@datadog/browser-rum';
import { useLazyQuery } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect, useMemo } from 'react';

import { GET_BOOTSTRAP } from '../../services/bootstrap';

import { createLogger } from '../../utils/logger';

import constants from '../../constants';
import { Routes } from '../../utils/constants/routes';

const log = createLogger('userProvider');

const useStyles = makeStyles({
  loading: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const UserContext = React.createContext({});

export const auth = new auth0.WebAuth({
  audience: process.env.AUTH_AUDIENCE,
  domain: process.env.AUTH_DOMAIN,
  clientID: process.env.AUTH_CLIENT_ID,
  prompt: 'none',
  redirectUri: `${window.location.origin}/`,
  responseType: 'token',
});

const login = () => auth.authorize();

const checkSession = () =>
  new Promise((resolve, reject) => {
    auth.checkSession({}, (err, authenticated) => {
      if (err) reject(err);

      return resolve(authenticated);
    });
  });

const logout = () => {
  log.info('user logout fired.');
  localStorage.removeItem(constants.STORAGE_TOKEN_KEY);

  auth.logout({
    returnTo: `${window.location.origin}/landing`,
  });
};

const parseHash = () =>
  new Promise((resolve, reject) => {
    auth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        reject(err);
        return;
      }

      if (!authResult) resolve(null);

      auth.client.userInfo(authResult.accessToken, () => {
        resolve(authResult.accessToken);
      });
    });
  });

const getDecodedToken = () => {
  const token = localStorage.getItem(constants.STORAGE_TOKEN_KEY);
  if (!token) return null;

  try {
    return decode(token);
  } catch (e) {
    return null;
  }
};

const UserProvider = (props) => {
  log.verbose('UserProvider Loaded');
  const { token, isLoggedIn } = useMemo(async () => {
    log.verbose(`:::UserProvider Token Check:::  TIME:${Math.round(new Date() / 60000)}`);
    const decoded = getDecodedToken();
    if (decoded) {
      const timestamp = Math.round(new Date() / 1000);
      let loggedIn = true;
      const expired = decoded.exp < timestamp;

      if (expired) {
        try {
          await auth.renewSession();
        } catch (err) {
          loggedIn = false;
        }
      }

      return {
        token,
        isLoggedIn: loggedIn,
      };
    }
    return {
      token: null,
      isLoggedIn: false,
    };
  }, [Math.round(new Date() / 60000)]);

  const [loadCombinedData] = useLazyQuery(GET_BOOTSTRAP, {
    onCompleted: (data) => {
      log.verbose('combinedData', data);
      datadogRum.setUser({
        id: data.currentUser.id,
        name: `${data.currentUser.firstName} ${data.currentUser.lastName}`,
        email: data.currentUser.email,
        accountId: data.currentAccount.id,
        accountName: data.currentAccount.shortId,
      });
    },
    fetchPolicy: 'cache-first',
  });

  const [user, setUser] = useState({
    isLoggedIn,
    token,
  });

  const [isLoading, setLoading] = useState(!isLoggedIn);
  const classes = useStyles();

  const resetLoading = _.partial(setLoading, false);

  useEffect(() => {
    const decoded = getDecodedToken();

    if (decoded) {
      checkSession().then(handleRefresh).catch(handleError).finally(resetLoading);

      return;
    }

    parseHash()
      .then((validatedToken) => {
        if (validatedToken) {
          handleLoggedIn(validatedToken);
        }
      })
      .catch(handleError)
      .finally(resetLoading);

    setTimeout(() => {}, 10000);
  }, []);

  const handleRefresh = ({ accessToken }) => {
    setToken(accessToken);
  };

  const handleError = () => {
    const { history } = props;

    localStorage.removeItem(constants.STORAGE_TOKEN_KEY);

    setUser({
      isLoggedIn: false,
      token: null,
    });

    history.push(Routes.LANDING);
    setLoading(false);
  };

  const setToken = (validatedToken) => {
    setUser({
      isLoggedIn: true,
      token: validatedToken,
    });

    localStorage.setItem(constants.STORAGE_TOKEN_KEY, validatedToken);

    loadCombinedData();
  };

  const handleLoggedIn = (validatedToken) => {
    const { history } = props;

    setToken(validatedToken);

    history.push(Routes.ROOT);
    setLoading(false);
  };

  return (
    <UserContext.Provider
      value={{
        handlers: {
          onLogin: login,
          onLogout: logout,
        },
        user,
      }}
    >
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        props.children
      )}
    </UserContext.Provider>
  );
};

export default withRouter(UserProvider);
