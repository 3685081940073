/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';
import { datadogRum } from '@datadog/browser-rum';
import ReactGA from 'react-ga4';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import strings from './strings';
import theme from './theme';
import App from './routes';

import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import UserProvider from './components/UserProvider';
import ScrollToTop from './components/ScrollToTop';
import NoInternetToast from './components/NoInternetToast';

import client from './services/client';

import constants from './constants';

const token = localStorage.getItem(constants.STORAGE_TOKEN_KEY);

ReactGA.initialize('G-CL5RYQDC7B');

if (process.env.ENV_NAME !== 'local') {
  datadogRum.init({
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_TOKEN,
    site: 'datadoghq.com',
    service: process.env.DATADOG_SERVICE,
    env: process.env.ENV_NAME,
    version: process.env.SHA || 'N/A',
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: process.env.API_DOMAINS,
  });
}

const cubejsApi = cubejs(token || '', {
  apiUrl: `${process.env.CUBE_JS_APP_BASE_URL}/cubejs-api/v1`,
});

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} maxSnack={5}>
          <CubeProvider cubejsApi={cubejsApi}>
            <ApolloProvider client={client}>
              <HelmetProvider>
                <BrowserRouter>
                  <ConfirmProvider>
                    <UserProvider>
                      <Helmet titleTemplate={`%s | ${strings.SITE_NAME}`} defaultTitle={strings.DEFAULT_PAGE_TITLE} />
                      <ScrollToTop />
                      <CssBaseline />
                      <App />
                      <NoInternetToast />
                    </UserProvider>
                  </ConfirmProvider>
                </BrowserRouter>
              </HelmetProvider>
            </ApolloProvider>
          </CubeProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </>,
);
