import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import groupBy from 'lodash/groupBy';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { grey, blue } from '@material-ui/core/colors';

import { LinearProgress } from '@material-ui/core';
import SearchForm from './form';

import { createLogger } from '../../utils/logger';
const log = createLogger('SearchModal');

const greyBG = '#504f52';

const useStyles = makeStyles((theme) => ({
  test: {
    color: theme.color,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 600,
    height: 500,
    backgroundColor: greyBG,
    color: 'white',
    overflow: 'hidden',
    borderRadius: 5,
  },
  list: {
    height: 464,
    overflowY: 'scroll',
    backgroundColor: grey[600],
    margin: 0,
    padding: 0,
  },
  listSubheader: {
    backgroundColor: grey[700],
    fontWeight: 'bold',
    paddingTop: 2,
    paddingBottom: 2,
    color: 'white',
    lineHeight: 'normal',
    textTransform: 'uppercase',
  },
  listItem: {
    '&:hover': {
      backgroundColor: blue[500],
    },
  },
  listItemText: {
    lineHeight: 'normal',
    marginTop: 3,
    marginBottom: 3,
  },
  listItemTextPrimary: {
    float: 'left',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '1rem',
    lineHeight: 'normal',
    fontWeight: 500,
  },
  listItemTextSecondary: {
    float: 'right',
    color: grey[300],
    textTransform: 'capitalize',
    fontSize: '0.75rem',
  },
}));

const ResultItem = ({ item, handler }) => {
  const classes = useStyles();
  let path;
  switch (item.type) {
    case 'application':
      path = `/inventory/application/${item.id}`;
      break;
    case 'location':
      path = `/location/${item.id}`;
      break;
    case 'technology':
      path = `/inventory/technology/${item.id}`;
      break;
    case 'vendor':
      path = `/inventory/vendor/${item.id}`;
      break;
    case 'capability':
      path = `/inventory/capability/${item.id}`;
      break;
    case 'skill':
      path = `/inventory/skill/${item.id}`;
      break;
    case 'person':
      path = `/people/${item.id}`;
      break;
    case 'service':
      path = `/inventory/service/${item.id}`;
      break;
    case 'component':
      path = `/inventory/component/${item.id}`;
      break;
    case 'business':
      path = `/inventory/business/${item.id}`;
      break;
    case 'portfolio':
      path = `/inventory/portfolio/${item.id}`;
      break;
    case 'role':
      path = `/people/roles/${item.id}`;
      break;
    case 'team':
      path = `/people/teams/${item.id}`;
      break;
    case 'assignment':
      path = `/people/assignments/${item.id}`;
      break;
    case 'component-type':
      break;
    default:
      log.error('was not able to resolve a path for search result');
  }

  return (
    <ListItem className={classes.listItem} button component={Link} to={path} onClick={handler}>
      {/* <ListItemIcon>
      <InboxIcon />
    </ListItemIcon> */}
      <ListItemText
        className={classes.listItemText}
        primary={
          <Typography component="span" variant="body1" className={classes.listItemTextPrimary} color="textPrimary">
            {item.name}
          </Typography>
        }
        secondary={
          <Typography component="span" variant="body1" className={classes.listItemTextSecondary} color="textPrimary">
            {item.type}
          </Typography>
        }
      />
    </ListItem>
  );
};

const Results = ({ results, handler, loading }) => {
  const classes = useStyles();
  const resultsSorted = groupBy(results, 'type');
  const resultsArray = [];
  Object.keys(resultsSorted).map((key) => resultsArray.push({ type: key, items: resultsSorted[key] }));

  if (loading) return <LinearProgress />;

  return (
    <List className={classes.list} component="nav" aria-label="Search Results">
      {resultsArray.map((types) => (
        <React.Fragment key={types.name}>
          <ListSubheader className={classes.listSubheader}>{types.name}</ListSubheader>
          {types.items.map((item) => (
            <ResultItem key={item.id} item={item} handler={handler} />
          ))}
        </React.Fragment>
      ))}
    </List>
  );
};

const SearchModal = ({ open, handler }) => {
  const classes = useStyles();
  const [results, setResults] = React.useState([]);
  const [loadingState, setLoadingState] = React.useState(false);

  const handleNavigate = () => {
    handler();
    setResults([]);
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleNavigate}
      // aria-labelledby="simple-modal-title"
      // aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <SearchForm callback={setResults} loadingCallBack={setLoadingState} />
        <Results handler={handleNavigate} results={results} loading={loadingState} />
      </div>
    </Modal>
  );
};

export default SearchModal;
