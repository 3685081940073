export const Routes = {
  ROOT: '/',
  LANDING: '/landing',
  FORGOT_PASSWORD: '/forgot-password',
  LOGOUT: '/logout',
  HOME: '/home',
  PROFILE: '/profile',
  CANVAS: '/canvas',
  DATA_FLOW: '/data-flow',
  REPORTING_EXPLORE: '/reporting/explore',
  REPORTING_DASHBOARD: '/reporting/dashboard',
  EXPLORE: '/explore',
  EXPLORE_CAPABILITIES: '/explore/capabilities',
  INVENTORY: '/inventory',
  INVENTORY_PORTFOLIOS: '/inventory/portfolio',
  INVENTORY_APPLICATIONS: '/inventory/application',
  INVENTORY_COMPONENTS: '/inventory/component',
  INVENTORY_CAPABILITIES: '/inventory/capability',
  INVENTORY_BUSINESSES: '/inventory/business',
  SINGLE_PORTFOLIO: '/inventory/portfolio/:id',
  SINGLE_APPLICATION: '/inventory/application/:id',
  SINGLE_COMPONENT: '/inventory/component/:id',
  SINGLE_CAPABILITY: '/inventory/capability/:id',
  SINGLE_BUSINESS: '/inventory/business/:id',
  PEOPLE: '/people',
  TEAMS: '/people/teams',
  ROLES: '/people/roles',
  SINGLE_PERSON: '/people/:id',
  SINGLE_TEAM: '/people/teams/:id',
  SINGLE_TEAM_WITH_TAB: '/people/teams/:id/:activeTab(summary|technologies|events|locations)',
  SINGLE_TEAM_SUMMARY: '/people/teams/:id/summary',
  SINGLE_TEAM_DETAILED: '/people/teams/:id/detailed',
  SINGLE_TEAM_TECHNOLOGIES: '/people/teams/:id/technologies',
  SINGLE_TEAM_TECHNOLOGY: '/people/teams/:id/technologies/:techId',
  SINGLE_TEAM_EVENTS: '/people/teams/:id/events',
  SINGLE_TEAM_LOCATIONS: '/people/teams/:id/locations',
  SINGLE_ROLE: '/people/roles/:id',
  SETTINGS: '/settings',
  SETTINGS_BLUEPRINTS: '/settings/blueprints',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_INTEGRATIONS: '/settings/integrations',
  SETTINGS_API_KEYS: '/settings/api-keys',
  SETTINGS_EDIT_USER: '/settings/users/:id',
  SETTINGS_SINGLE_INTEGRATION: '/settings/integrations/:name',
};
