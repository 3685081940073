import { gql } from '@apollo/client';

export const SEARCH_ALL = gql`
  query ($query: String!, $limit: Int) {
    search(query: $query, limit: $limit) {
      relationships {
        id
        type
      }
      entities {
        id
        type
        name
      }
      blueprints {
        id
        name
      }
    }
  }
`;

export const SEARCH_ENTITIES = gql`
  query ($query: String!, $limit: Int) {
    search(query: $query, limit: $limit) {
      entities {
        id
        type
        name
      }
    }
  }
`;
