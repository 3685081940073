import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import MuiToolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MuiButton from '@mui/material/Button';
import MuiMenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import SearchModal from '../Search';
import Logo from '../../images/devgrid_logo.png';
import { Icons } from '../../utils/constants/icons';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow: '0px 2px 6px 0px #454D5414',
  zIndex: theme.zIndex.drawer + 1,
}));

const Toolbar = styled(MuiToolbar)({
  height: 64,
  display: 'flex',
  justifyContent: 'space-between',
});

const HeaderLogo = styled('img')({
  height: 20,
  display: 'block',
});

const Tabs = styled(MuiTabs)({
  height: '100%',
  '& .MuiTabs-flexContainer': {
    height: '100%',
  },
});

const NavTab = styled(MuiTab)({
  fontFamily: 'Inter',
  fontSize: 14,
  fontWeight: 400,
  color: '#2E3338',
  height: '100%',
  textTransform: 'capitalize',
  padding: '6px 24px',
  '&.active': {
    color: '#2E3338',
    fontWeight: 500,
  },
  '&:hover': {
    color: '#3098E8',
  },
});

const SearchButton = styled(MuiButton)({
  fontSize: 14,
  fontWeight: 500,
  borderColor: '#DADEF1',
  textTransform: 'none',
  letterSpacing: '0.6px',
  padding: '7px 16px 7px 12px',
  '&:hover': {
    borderColor: '#DADEF1',
  },
});

const AvatarButton = styled(IconButton)(({ theme }) => ({
  fontSize: 14,
  padding: 8,
  backgroundColor: theme.palette.primary[100],
  '&:hover, &:active': {
    backgroundColor: theme.palette.primary[100],
  },
}));

const MenuItem = styled(MuiMenuItem)({
  fontSize: 14,
});

const LinkTab = (props) => <NavTab component={NavLink} {...props} />;

const topMenuItems = [
  { to: '/explore', label: 'Explore' },
  { to: '/inventory', label: 'Inventory' },
  { to: '/people', label: 'People' },
  // { to: '/reporting/explore', label: 'Reports' },
];

const calculateRoot = (location) => `/${location.split('/')[1]}`;

const NavLinks = () => {
  const location = useLocation();
  const pathRoot = calculateRoot(location.pathname);
  const isNavLinksContainsCurrentLocation = topMenuItems.some((link) => link.to === pathRoot);
  const tabsValue = isNavLinksContainsCurrentLocation && pathRoot !== '/' ? pathRoot : false;

  return (
    <Tabs value={tabsValue}>
      {topMenuItems.map((item) => (
        <LinkTab key={item.to} label={item.label} to={item.to} value={item.to} activeClassName="active" />
      ))}
    </Tabs>
  );
};

const TopNav = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchClick = () => {
    setSearchOpen(!searchOpen);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SearchModal open={searchOpen} handler={handleSearchClose} />
      <AppBar position="fixed">
        <Toolbar variant="dense">
          <Box pl="10px">
            <Link component={NavLink} to="/">
              <HeaderLogo src={Logo} alt="Home" />
            </Link>
          </Box>
          <Box alignSelf="stretch">
            <NavLinks />
          </Box>
          <Box display="flex" alignItems="center">
            <SearchButton
              onClick={handleSearchClick}
              variant="outlined"
              startIcon={<Icons.SEARCH />}
              // disableRipple
              // disableFocusRipple
              // disableTouchRipple
            >
              Search
            </SearchButton>

            <Box ml="24px">
              <AvatarButton
                variant="rounded"
                color="primary"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
              >
                <Icons.PERSON />
              </AvatarButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem component={NavLink} to="/settings" onClick={handleClose}>
                  Settings
                </MenuItem>
                <MenuItem component={NavLink} to="/profile" onClick={handleClose}>
                  Profile
                </MenuItem>
                <MenuItem component={NavLink} to="/logout" onClick={handleClose}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default TopNav;
